import React from "react";
import { Position } from "../../../model/position/Positions";
import { pinnedNotePositions, noteList } from "../../../model/services";
import { NoteId } from "../../../model/types";
import { SearchQuery, SearchQuerySetter } from "../../../search/SearchQuery";
import { useNotifySidebarUpdate } from "../../atoms/sidebarUpdate";
import { SidebarLinkGroup } from "../SidebarLinkGroup";
import { SidebarSectionEmptyState } from "../SidebarSectionEmptyState";
import { PinnedNotesList } from "./PinnedNotesList";

const updateNoteIds = (draggedId: NoteId, result: NoteId[]) => {
  // set the new position, depending on where the dragged note was moved to
  const indexOfNoteBefore = result.indexOf(draggedId) - 1;
  let pos: Position;
  if (indexOfNoteBefore >= 0) {
    const precedingNoteId = result[indexOfNoteBefore];
    const precedingNotePos = noteList.get(precedingNoteId)!.positionInPinned!;
    pos = pinnedNotePositions.generateAfter(precedingNotePos)[0];
  } else {
    pos = pinnedNotePositions.generateFirst()[0];
  }

  // update the note repository and pinned notes position list
  noteList.update({ id: draggedId, positionInPinned: pos });
};

const getPinnedNoteIdsOrdered = () => {
  return pinnedNotePositions
    .getAll()
    .filter((note) => note.deletedAt === null)
    .sort((a, b) => {
      const positionA = a.positionInPinned ?? a.position;
      const positionB = b.positionInPinned ?? b.position;
      if (positionA < positionB) return -1;
      if (positionA > positionB) return 1;
      // when positions are equal, we make the order stable by ordering by createdAt
      if (a.createdAt < b.createdAt) return -1;
      if (a.createdAt > b.createdAt) return 1;
      return 0;
    })
    .map((element) => {
      return element.id;
    })
    .filter((val) => val) as NoteId[]; // filter undefined););
};

export const PinnedNotesSection: React.FC<{
  search: SearchQuery;
  setSearch: SearchQuerySetter;
}> = ({ search, setSearch }) => {
  const noteIds = getPinnedNoteIdsOrdered();
  const updateSidebar = useNotifySidebarUpdate();

  return (
    <SidebarLinkGroup header="Pinned Notes" type="pinned">
      {noteIds.length ? (
        <PinnedNotesList
          noteIds={noteIds}
          setNotes={(...args) => {
            updateNoteIds(...args);
            updateSidebar();
          }}
          search={search}
          setSearch={setSearch}
        />
      ) : (
        <SidebarSectionEmptyState text="Tap the pin in a note's menu to stick it here" />
      )}
    </SidebarLinkGroup>
  );
};
