import React from "react";
import { isIOs } from "../../utils/environment";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  selected: boolean;
};

export const SelectedLink = React.forwardRef<HTMLDivElement, Props>(
  ({ selected, onMouseDown, ...props }: Props, ref) => {
    const classes = `selected-link ${selected && "selected"}`;
    return (
      <div
        className={classes}
        ref={ref}
        style={isIOs ? { outline: "none" } : {}}
        {...props}
        onClick={onMouseDown}
      />
    );
  },
);
