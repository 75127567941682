import { useCallback } from "react";
import { useHotkeys } from "./useHotkeys";
import { Shortcut } from "./shortcuts";
import { useRouter } from "next/router";

export function useNavigateShortcut(shortcut: Shortcut, page: string) {
  const router = useRouter();
  const navToPage = useCallback(() => {
    router.push(page);
  }, [page, router]);
  useHotkeys(shortcut, navToPage);
}
