import { mapBlockTokens } from "../utils/tokenIterators/mapBlockTokens";
import { CodeblockToken, Note, ParagraphToken } from "../model/types";
import { InlineToken } from "../model/types";
import { NoteList } from "../model/noteList";

/**
 *  Iterate though all notes, then all top level blocks, to count all tokens.
 * Used as the base for all other counting functions
 * - _todo: Can be memoized if slow_
 * @param filterMap function that is used in a map function. Any non-false key
 * will be returned as the key in the return Map, with a count of times that non-false
 * key was returned as the key
 * @param countDuplicates if the return value should include duplicates in notes
 * */
function countTokensPerNote(
  notes: Note[],
  filterMap: (t: InlineToken) => string | false,
  countDuplicates = false,
) {
  const filterPerType =
    (list: string[]) => (bt: ParagraphToken | CodeblockToken) => {
      bt.content.forEach((token) => {
        const r = filterMap(token);
        if (r) list.push(r);
      });
    };

  const out = new Map<string, number>();
  // lots of loops
  notes.forEach((note) => {
    const list: string[] = [];
    note.tokens.forEach(mapBlockTokens(filterPerType(list)));
    // use a set to remove the duplicates
    const targetList = countDuplicates ? list : new Set<string>(list);
    targetList.forEach((id: string) => {
      if (out.has(id)) {
        out.set(id, out.get(id)! + 1);
      } else {
        out.set(id, 1);
      }
    });
  });

  return out;
}

/** Iterate though all tokens in document, counting hashtags in each note */
export function countHashtag(
  noteList: NoteList,
): { id: string; count: number; content: string }[] {
  const hashtags = noteList.hashtags.getAllItems();
  return hashtags.map((entry) => {
    const id = entry[0];
    return {
      count: entry[1].size,
      id,
      content: id,
    };
  });
}

/** Iterate though all tokens in document, counting total number of todos
 * not todos per note
 */
export function countTodos(notes: Note[]): number {
  const counts = countTokensPerNote(
    notes,
    (token) =>
      token.type === "checkbox" && token.isChecked === false
        ? "unchecked"
        : false,
    true,
  );
  return counts.get("unchecked") || 0;
}
