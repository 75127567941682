import React, { useState, useCallback, useMemo } from "react";
import List from "@researchgate/react-intersection-list";
import { SidebarItem } from "../SidebarItem/SidebarItem";
import { SidebarLinkGroup } from "./SidebarLinkGroup";
import { SidebarSectionEmptyState } from "./SidebarSectionEmptyState";

/** data required required to render a sidebar element */
export interface SidebarElement {
  id: string;
  content: string; // Hashtag,
  count?: number;
  setSearch: () => void; // a preset SearchQuerySetter
}

interface Props {
  header: string;
  type: "hashtag";
  elements: SidebarElement[];
  newItemId?: string;
  setNewItemId?: (id: string) => void;
}

const LOCAL_STORAGE_KEY = "hashtag_sort";
export type HashtagSortMode = "popularity" | "alphabetical";

const ElementBrowser: React.FC<Props> = ({ header, type, elements: items }) => {
  const [sortMode, setSortMode] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEY) || "popularity",
  );

  const setPersistedSortMode = (newSortMode: HashtagSortMode) => {
    setSortMode(newSortMode);
    localStorage.setItem(LOCAL_STORAGE_KEY, newSortMode);
  };

  const sortedItems = useMemo(() => {
    if (type !== "hashtag") return items;
    const res = [...items];
    if (sortMode === "popularity") {
      res.sort((a, b) => b.count! - a.count!);
    } else {
      res.sort(function (a, b) {
        return a.content.trim().localeCompare(b.content.trim(), undefined, {
          sensitivity: "base",
        });
      });
    }
    return res;
  }, [items, sortMode, type]);

  const renderToken = useCallback(
    (index: number) => {
      const { id, content, count, setSearch } = sortedItems[index];

      return (
        <SidebarItem
          key={id}
          itemId={id}
          count={count || null}
          content={content}
          setSearch={setSearch}
        />
      );
    },
    [sortedItems],
  );

  return (
    <SidebarLinkGroup
      header={header}
      type={type}
      sortMode={sortMode}
      setSortMode={setPersistedSortMode}
    >
      {items.length ? (
        <List itemCount={items.length} renderItem={renderToken} pageSize={50} />
      ) : (
        <SidebarSectionEmptyState text="Type # to add your first hashtag" />
      )}
    </SidebarLinkGroup>
  );
};

export default ElementBrowser;
