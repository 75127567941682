import React from "react";
import Sidebar from "../sidebar/Sidebar";
import { useAuth } from "../auth/useAuth";
import { Header } from "./Header";
import styled from "@emotion/styled";
import { EditorWithAutocomplete } from "./EditorWithAutocomplete";
import { Footer } from "./Footer";
import { isMobileWidth } from "../utils/environment";
import { useInitNavigationHistory } from "./navigateHistory";

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
`;

const contentMaxWidth = 720;

export const EditorPage = () => {
  const { user } = useAuth();
  useInitNavigationHistory();
  if (user == null) return null;
  return (
    <>
      <Header contentMaxWidth={contentMaxWidth} />
      <ContentContainer role="presentation">
        <Sidebar />
        <EditorWithAutocomplete />
      </ContentContainer>
      {isMobileWidth && <Footer />}
      <div id="modal-container" />
    </>
  );
};
