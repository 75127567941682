import {
  SortableTree,
  TreeItem,
  TreeItemComponentProps,
} from "dnd-kit-sortable-tree";
import React, { useEffect, useState } from "react";
import { FolderList } from "../../../model/folderList";
import { folderList, folderPositions } from "../../../model/services";
import { Folder, FolderId } from "../../../model/types";
import { assert } from "../../../utils/assert";
import { SidebarLinkGroup } from "../SidebarLinkGroup";
import { SidebarSectionEmptyState } from "../SidebarSectionEmptyState";
import { ExpandableAndDraggable } from "./ExpandableAndDraggable";
import { FolderItem } from "./FolderItem";

export const FoldersSection = () => {
  const [newId, setNewId] = useState<string | null>(null);
  const folders = folderList.getAll();
  return (
    <SidebarLinkGroup header="Folders" type="folder" setNewItemId={setNewId}>
      {folders.length ? (
        <FoldersList folders={folders} newId={newId} setNewId={setNewId} />
      ) : (
        <SidebarSectionEmptyState text="Add to help organise your notes" />
      )}
    </SidebarLinkGroup>
  );
};

type DraggableFolder = {
  id: FolderId; // already added by dnd, added for clarity
  name: string;
  newId: FolderId | null;
  setNewId: any;
};

export const FoldersList = ({
  folders,
  newId,
  setNewId,
}: {
  folders: Folder[];
  newId: FolderId | null;
  setNewId: any;
}) => {
  const [items, setItems] = useState(
    nest(folders, null, [], { newId, setNewId }),
  );
  // we should expand the folder when one folder is dropped in another one.
  useEffect(() => {
    const toBeExpanded = persistChanges(folderList, items);
    if (toBeExpanded.length) {
      expand(items, toBeExpanded);
      setItems(items);
    }
  }, [items]);

  useEffect(() => {
    setItems((items) => {
      return nest(folders, null, expandedIds(items), { newId, setNewId });
    });
  }, [folders, newId, setNewId]);

  return (
    <SortableTree
      items={items}
      onItemsChanged={setItems}
      TreeItemComponent={DraggableFolderItem}
    />
  );
};

const DraggableFolderItem = React.forwardRef<
  HTMLDivElement,
  TreeItemComponentProps<DraggableFolder>
>((props, ref) => (
  <ExpandableAndDraggable key={props.item.id} {...props} ref={ref}>
    <FolderItem {...props.item} handleProps={props.handleProps} />
  </ExpandableAndDraggable>
));

// Turns a flat list of folders into a nested tree
function nest(
  folders: Folder[],
  root: null | string,
  expanded: FolderId[],
  extraProps: {
    newId: FolderId | null;
    setNewId: any;
  },
): TreeItem<DraggableFolder>[] {
  return folders
    .filter((f) => f.parentId === root)
    .sort((a, b) => (a.position < b.position ? -1 : 1))
    .map((folder) => ({
      id: folder.id,
      name: folder.name,
      children: nest(folders, folder.id, expanded, extraProps),
      collapsed: !expanded.includes(folder.id),
      ...extraProps,
    }));
}

const zip = <A, B>(as: A[], b: B) =>
  as.map((a) => ({
    ...a,
    ...b,
  }));

// Reads a tree view of folders, detect changes in position and parent, and persist those.
function persistChanges(
  list: FolderList,
  folders: TreeItem<DraggableFolder>[],
) {
  const toExpand = [];
  const depthFirst = zip<
    TreeItem<DraggableFolder>,
    { parentId: string | null }
  >(folders, {
    parentId: null,
  });
  let currPosition = folderPositions.START_POS;
  while (depthFirst.length) {
    const { id, parentId, children } = depthFirst.shift()!;
    const folder = list.get(id)!;
    assert(
      Boolean(folder),
      "folder present in sidebar, but missing in storage.",
    );

    // the folder changed parent or is out of order.
    if (folder.parentId !== parentId || currPosition >= folder.position) {
      // nest or/and reindex
      folder.parentId = parentId;
      if (currPosition >= folder.position)
        folder.position = folderPositions.generateAfter(currPosition)[0];
      list.update({
        id,
        position: folder.position,
        parentId,
      });
      toExpand.push(parentId!); // we expand the parent of the node that changed.
    }
    currPosition = folder.position;
    if (children?.length) {
      depthFirst.unshift(...zip(children, { parentId: id }));
    }
  }
  return toExpand;
}

// Returns the expanded folderIds, depth first.
const expandedIds = (folders: TreeItem<DraggableFolder>[]): FolderId[] => {
  return folders.flatMap((folder) => {
    const elements = folder.children ? expandedIds(folder.children) : [];
    if (!folder.collapsed) elements.push(folder.id);
    return elements;
  });
};

// expand all the folder ids in folder. Mutable function.
const expand = (folders: TreeItem<DraggableFolder>[], ids: FolderId[]) => {
  return folders.forEach((folder) => {
    if (ids.includes(folder.id)) {
      folder.collapsed = false;
    }
    expand(folder.children ?? [], ids);
  });
};
