import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { MoreHorizontal } from "react-feather";
import { isTouchDevice } from "../../utils/environment";
import { colors, radii, shadows } from "../../utils/style";

export const CountMenu = styled.div(
  (props: { isSelected: boolean }) => `
  font-size: 12px;
  justify-content: flex-end;
  border-radius: 2px;
  margin: 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: ${props.isSelected ? colors.bg.primary : colors.text.secondary};
  border: 0;
  background-color: transparent;
  position: relative;
`,
);

const DotMenu = styled.button(
  (props: { isSelected: boolean }) => `
  /* font-size: 12px; */
  border-radius: ${radii.medium};
  margin: 0;
  padding: 1px 4px 1px 4px;
  display: flex;
  flex-shrink: 0;
  color: ${props.isSelected ? colors.bg.primary : colors.text.secondary};
  border: 0;
  background-color: ${
    props.isSelected ? colors.transparent : colors.bg.secondary
  };
  position: relative;
  :hover {
    background-color: ${colors.bg.accent.tertiary};
    color: ${props.isSelected ? colors.bg.secondary : colors.text.accent};
  }
`,
);

const MenuContainer = styled.div`
  background: ${colors.bg.secondary};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: absolute;
  word-wrap: normal;
  box-sizing: content-box;
  padding: 4px 0;
  right: 27px;
  border: 1px solid ${colors.border.secondary};
  box-shadow: ${shadows.heavy};
  top: 24px;
  z-index: 2;
  border-radius: ${radii.large};
`;

const DropdownButton = styled.button`
  background: transparent;
  border: 0;
  width: 100%;
  min-width: 8em;
  text-align: left;
  padding: 6px 16px;
  color: ${colors.text.primary};
  :hover {
    background: ${colors.bg.tertiary};
  }
  z-index: 1;
`;

interface Props {
  isHovering: boolean;
  isSelected: boolean;
  setIsHoveringSidebarItem: (isHovering: boolean) => void;
  setIsEditing: (isEditing: boolean) => void;
  errorMessage: string | null;
  deleteFolderAndRedirect: () => void;
}

export const Menu: React.FC<Props> = ({
  isHovering: isHoveringSidebarItem,
  isSelected: isSelectedSidebarItem,
  setIsHoveringSidebarItem,
  errorMessage,
  setIsEditing,
  deleteFolderAndRedirect,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuContainerRef = useRef<HTMLDivElement>(null);
  const menuDotsRef = useRef<HTMLButtonElement>(null);

  const onRenameButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (errorMessage != null) return;
    setIsEditing(true);
    setIsMenuOpen(false);
    setIsHoveringSidebarItem(false);
  };

  useEffect(() => {
    const onOutsideClick = (e: MouseEvent) => {
      if (e.target == null) return;
      if (
        // if we click anywhere other than the menu or the dots
        // close the menu
        menuContainerRef.current?.contains(e.target as any) ||
        menuDotsRef.current?.contains(e.target as any)
      ) {
        return;
      }
      setIsMenuOpen(false);
      document.removeEventListener("mousedown", onOutsideClick);
    };
    if (isMenuOpen) {
      document.addEventListener("mousedown", onOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", onOutsideClick);
    };
  }, [setIsMenuOpen, isMenuOpen]);

  if (!isHoveringSidebarItem && !isTouchDevice) {
    return null;
  }

  return (
    <>
      <DotMenu
        isSelected={isSelectedSidebarItem}
        ref={menuDotsRef}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <MoreHorizontal size={16} />
      </DotMenu>
      {isMenuOpen && (
        <MenuContainer
          ref={menuContainerRef}
          onClick={(e) => e.stopPropagation()}
          onMouseLeave={() => {
            // once we enter the menu, leaving the menu div closes it
            setIsMenuOpen(false);
          }}
        >
          <DropdownButton onClick={onRenameButtonClick}>Rename</DropdownButton>
          <DropdownButton onClick={deleteFolderAndRedirect}>
            Delete
          </DropdownButton>
        </MenuContainer>
      )}
    </>
  );
};
