import styled from "@emotion/styled";
import React from "react";
import { Plus } from "react-feather";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { folderList } from "../../../model/services";
import { useSearchState } from "../../../search/searchStateAtom";
import { colors, radii } from "../../../utils/style";

const StyledButton = React.memo(
  styled.button`
    font-size: 16px;
    border-radius: ${radii.medium};
    margin: 0;
    width: 28px;
    height: 28px;
    display: grid;
    place-items: center;
    flex-shrink: 0;
    border: none;
    box-sizing: border-box;
    color: ${colors.text.secondary};
    background: none;

    &:hover {
      color: ${colors.text.accent};
      background-color: ${colors.bg.accent.tertiary};
    }
  `,
);

export const CreateNewFolderButton: React.FC<{
  setNewItemId: (id: string) => void;
}> = React.memo(({ setNewItemId }) => {
  const [, setSearch] = useSearchState();
  const onClick = async () => {
    const [folder] = folderList.insert();
    setSearch({
      type: "folder",
      q: folder.id,
    });
    setNewItemId(folder.id);
    trackEvent(["FOLDER", "CREATED", folder.id]);
  };
  return (
    <StyledButton onClick={onClick}>
      <Plus size={16} strokeWidth="2.5" />
    </StyledButton>
  );
});
