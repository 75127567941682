import React from "react";
import { NoteId } from "../../../model/types";
import { SearchQuery, SearchQuerySetter } from "../../../search/SearchQuery";
import { colors } from "../../../utils/style";
import { PinnedNote } from "./PinnedNote";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";

export const PinnedNotesList: React.FC<{
  noteIds: NoteId[];
  setNotes: (draggedId: NoteId, result: NoteId[]) => void;
  search: SearchQuery;
  setSearch: SearchQuerySetter;
}> = ({ noteIds, search, setSearch, setNotes }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const content = noteIds.map((id) => {
    return (
      <PinnedNote
        key={id}
        id={id}
        noteId={id}
        search={search}
        setSearch={setSearch}
      />
    );
  });

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = noteIds.indexOf(active.id);
      const newIndex = noteIds.indexOf(over.id);

      const newArr = arrayMove(noteIds, oldIndex, newIndex);
      setNotes(active.id, newArr);
    }
  };

  if (content.length === 0)
    return (
      <div
        style={{
          color: colors.text.secondary,
          fontSize: 14,
          textAlign: "left",
          marginLeft: "1em",
          marginTop: "0.4em",
          marginBottom: "0.4em",
          fontStyle: "italic",
        }}
      >
        (no notes referenced)
      </div>
    );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={noteIds} strategy={verticalListSortingStrategy}>
        {content}
      </SortableContext>
    </DndContext>
  );
};
