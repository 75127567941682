import React, { useEffect, useRef, useState } from "react";
import { RoutesOption } from "../routes";
import Link from "next/link";
import { useNavigateShortcut } from "../shortcuts/useNavigateShortcut";
import { getShortcutString, shortcuts } from "../shortcuts/shortcuts";
import styled from "@emotion/styled";
import { colors, mediaQueries } from "../utils/style";
import { useAtom } from "jotai";
import { SidebarContent } from "./SidebarContent";
import { isSidebarOpenAtom } from "./atoms/isSidebarOpenAtom";
import { Sliders } from "react-feather";

const SidebarContentContainer = styled.div`
  overflow-y: scroll;
  max-height: 100%;
  padding: 0px 16px 16px 16px;
  flex-grow: 1;
  scrollbar-width: thin;
`;

const SidebarContainer = styled.div<{ showSidebar: boolean }>(
  (props) => `
  flex-grow: 0;
  flex-shrink: 0;
  width: 300px;
  position: absolute;
  display: flex;
  top: var(--header-height);
  bottom: 0;
  background: ${colors.bg.secondary};
  border: 0;
  border-right: 1px solid ${colors.border.secondarySolid};
  list-style-type: none;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  transform: ${
    props.showSidebar ? "translateX(0px)" : "translateX(calc(-100% - 8px))"
  };
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
`,
);

const CogContainer = styled.a`
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  color: ${colors.text.secondary};
  background-color: ${colors.bg.secondary};
  padding: 0.75rem 1.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  border-top: 1px solid ${colors.border.secondary};
  bottom: 0;
  width: 100%;
  :hover {
    color: ${colors.text.primary};
  }
`;

const CogContent = styled.div`
  display: flex;
  align-items: center;
  span {
    padding-left: 0.5rem;
  }
`;

const SettingsCogButton = React.memo(() => {
  const tooltip = `Open Settings ${getShortcutString(
    shortcuts.toggleSettings,
  )}`;
  useNavigateShortcut(shortcuts.toggleSettings, RoutesOption.Settings);
  return (
    <Link href={RoutesOption.Settings}>
      <CogContainer>
        <CogContent>
          <Sliders size={16} />
          <span
            aria-label={tooltip}
            role="tooltip"
            data-microtip-position="top-right"
          >
            Settings
          </span>
        </CogContent>
      </CogContainer>
    </Link>
  );
});

const BlackFilter = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  opacity: 0.2;
  top: var(--header-height);
  background-color: black;
  z-index: 5;
  display: none;
  ${mediaQueries.medium} {
    display: flex;
  }
`;

/** Mostly CSS and style components, the majority of the logic is in
 * SidebarContent
 */
const Sidebar: React.FC = () => {
  const [showSidebar, setShowSidebar] = useAtom(isSidebarOpenAtom);

  // If the user "tabs" into the sidebar from e.g. the search bar, we want
  // the sidebar to reveal itself without needing to be toggled explicitly
  const [showSidebarFromFocus, setShowSidebarFromFocus] = useState(false);

  const shouldShowSidebar = showSidebar || showSidebarFromFocus;

  const sidebarRef = useRef<HTMLDivElement>(null);
  const sidebarFirstFocusableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // When the user closes the sidebar, we want to move focus out of the
    // sidebar, and when the user opens we want to move focus into it.
    const isFocusInSidebar = sidebarRef.current?.contains(
      document.activeElement,
    );
    if (showSidebar && !isFocusInSidebar) {
      sidebarFirstFocusableRef.current?.focus();
      return;
    }
    if (!showSidebar && isFocusInSidebar && document.activeElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }, [showSidebar, sidebarRef, sidebarFirstFocusableRef]);

  const onBlackFilterClick = () => setShowSidebar(false);

  useEffect(() => {
    const updateFocusState = () => {
      const isFocusWithin =
        document.activeElement &&
        sidebarRef.current?.contains(document.activeElement);
      setShowSidebarFromFocus(!!isFocusWithin);
    };
    window.addEventListener("focus", updateFocusState, true);
    window.addEventListener("blur", updateFocusState, true);
    return () => {
      window.removeEventListener("focus", updateFocusState, true);
      window.removeEventListener("blur", updateFocusState, true);
    };
  }, [setShowSidebarFromFocus]);

  return (
    <>
      {showSidebar && <BlackFilter onClick={onBlackFilterClick} />}
      <SidebarContainer
        role="complementary"
        id={shouldShowSidebar ? "sidebar-visible" : "sidebar-hidden"} // for testcafe
        ref={sidebarRef}
        showSidebar={shouldShowSidebar}
      >
        <SidebarContentContainer>
          <SidebarContent sidebarFirstFocusableRef={sidebarFirstFocusableRef} />
        </SidebarContentContainer>
        <SettingsCogButton />
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
