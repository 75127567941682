import React, { useState } from "react";
import styled from "@emotion/styled";
import { colors, transitions } from "../../utils/style";
import { CreateNewFolderButton } from "./Folders/CreateNewFolderButton";
import { isTouchDevice } from "../../utils/environment";
import { ChevronDown } from "react-feather";
import { SidebarListSortToggle } from "./SidebarListSortToggle";
import { HashtagSortMode } from "./ElementBrowser";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
const Header = styled.div`
  font-size: 14px;
  color: ${colors.text.secondary};
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
  user-select: none;
  position: sticky;
  background-color: ${colors.bg.secondary};
  top: 0px;
  z-index: 1;
  /* margin-bottom: 4px; */
  gap: 0.25rem;
`;

const Title = styled.div`
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  border-radius: var(--radius-medium);
  padding: 0.25rem 0.5rem;
  gap: 0.25rem;
  align-items: center;
  height: 28px;
  font-size: 13px;

  &:hover {
    color: ${colors.text.secondary};
    background: ${colors.bg.tertiary};
  }
`;

type SidebarLinkGroupType = "pinned" | "hashtag" | "folder";
const sidebarStatusAtom = {
  pinned: atomWithStorage("pinned", true),
  hashtag: atomWithStorage("hashtag", true),
  folder: atomWithStorage("folder", true),
};

export const SidebarLinkGroup: React.FC<{
  header: string | JSX.Element;
  type: SidebarLinkGroupType;
  helpMessage?: string;
  setNewItemId?: (id: string | null) => void;
  sortMode?: string;
  setSortMode?: (to: HashtagSortMode) => void;
  children?: React.ReactNode;
}> = ({
  header,
  type,
  helpMessage,
  setNewItemId,
  sortMode,
  setSortMode,
  children,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isExpanded, setIsExpanded] = useAtom(sidebarStatusAtom[type]);

  return (
    <div
      className={`selected-link-group selected-link-group--${type}`}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <Header>
        <Title
          tabIndex={0}
          onKeyPress={(e) => e.key === "Enter" && setIsExpanded(!isExpanded)}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {header}
          <ChevronDown
            size={16}
            style={{
              display: "inline-block",
              transform: isExpanded ? "none" : "rotate(-90deg)",
              transition: transitions.medium,
              color: colors.text.tertiary,
            }}
          />
          {helpMessage && (
            <div
              role="tooltip"
              data-microtip-position="left"
              aria-label={helpMessage}
              style={{
                display: "inline-block",
                height: 20,
                width: 20,
                textAlign: "center",
                borderRadius: "50%",
                padding: 0,
                fontSize: 14,
                verticalAlign: "top",
                position: "absolute",
                right: 0,
                background: colors.bg.secondary,
              }}
            >
              ?
            </div>
          )}
        </Title>
        {type === "hashtag" &&
          (isHovering || isTouchDevice) &&
          sortMode &&
          setSortMode && (
            <SidebarListSortToggle
              sortMode={sortMode}
              setSortMode={setSortMode}
            />
          )}
        {setNewItemId && (isHovering || isTouchDevice) && (
          <CreateNewFolderButton
            setNewItemId={(id: string) => {
              // open the sidebar
              setIsExpanded(true);

              // open the naming input for new folder
              setNewItemId(id);
            }}
          />
        )}
      </Header>
      {isExpanded && children}
    </div>
  );
};
