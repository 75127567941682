import styled from "@emotion/styled";
import React, { useState } from "react";
import { SearchQuery } from "../search/SearchQuery";
import { colors } from "../utils/style";
import { Calendar as CalendarIcon } from "react-feather";

type CalendarProps = {
  search: SearchQuery;
  setSearchDate: (date: Date) => void;
};

const MonthYearFormatter = Intl.DateTimeFormat([], {
  month: "long",
  year: "numeric",
});

const CalendarDayButton = styled.div`
  width: calc(100% / 7);
  height: 28px;
  line-height: 28px;
  border-radius: 6px;
  margin: 2px 4px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  :hover {
    background: ${colors.border.secondary};
  }
`;

const CalendarDay = ({
  month,
  search,
  setSearchDate,
  day,
}: CalendarProps & { month: Date; day: Date }) => {
  return (
    <CalendarDayButton
      className="calendarDay"
      onClick={() => {
        setSearchDate(day);
      }}
      style={{
        ...(month.getMonth() !== day.getMonth()
          ? {
              color: colors.text.secondary,
              opacity: 0.5,
            }
          : null),
        ...(search.type === "date" && search.q.getTime() === day.getTime()
          ? {
              color: colors.bg.primary,
              background: colors.text.accent,
            }
          : new Date().toDateString() === day.toDateString()
          ? {
              background: colors.bg.accent.tertiary,
            }
          : null),
      }}
    >
      {day.getDate()}
    </CalendarDayButton>
  );
};

const CalendarWeek = ({
  month,
  search,
  setSearchDate,
  days,
}: CalendarProps & { month: Date; days: Date[] }) => {
  return (
    <div
      className="calendarWeek"
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {days.map((day) => (
        <CalendarDay
          month={month}
          search={search}
          setSearchDate={setSearchDate}
          day={day}
          key={day.getTime()}
        />
      ))}
    </div>
  );
};

function getDatesByWeekInMonthCalendar(date: Date) {
  // We count backwards until Monday, then forwards until next month
  let firstCalendarDay = new Date(date.getTime());
  firstCalendarDay.setDate(1);
  while (firstCalendarDay.getDay() !== 1) {
    firstCalendarDay = new Date(firstCalendarDay.getTime() - 86400000);
  }
  const currentMonth = date.getMonth();
  let lastCalendarDay = new Date(date.getTime());
  while (lastCalendarDay.getMonth() === currentMonth) {
    lastCalendarDay = new Date(lastCalendarDay.getTime() + 86400000);
  }
  lastCalendarDay = new Date(lastCalendarDay.getTime() - 86400000);
  while (lastCalendarDay.getDay() !== 0) {
    lastCalendarDay = new Date(lastCalendarDay.getTime() + 86400000);
  }

  const daysInCalendarMonth = [];
  for (
    let date = firstCalendarDay;
    date <= lastCalendarDay;
    date = new Date(date.getTime() + 86400000)
  ) {
    daysInCalendarMonth.push(date);
  }

  let currentWeek: Date[] = [];
  const daysByWeek: Date[][] = [currentWeek];
  daysInCalendarMonth.forEach((day, i) => {
    if (i % 7 === 0) {
      currentWeek = [];
      daysByWeek.push(currentWeek);
    }
    currentWeek.push(day);
  });

  return daysByWeek;
}

const CalendarDefaultsButton = styled.button<{ selected?: boolean }>(
  ({ selected }) => `
  color: ${selected ? colors.bg.primary : "inherit"};
  background: ${selected ? colors.text.accent : "transparent"};
  border: 0;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  margin: 0 3px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: ${selected ? colors.text.accent : colors.bg.secondary};
  }
  `,
);

const CalendarArrowButton = styled.button`
  color: inherit;
  background: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 4px;
  height: 26px;
  min-width: 26px;
  :hover {
    background: ${colors.border.secondary};
  }
`;

function getStartOfToday(): Date {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
}

function getStartOfTomorrow(): Date {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 1);
  return d;
}

function getStartOfMonth(d: Date): Date {
  d.setDate(1);
  return d;
}
const CalendarMonth = ({
  month,
  setMonth,
  search,
  setSearchDate,
}: CalendarProps & { month: Date; setMonth: (d: Date) => void }) => {
  const daysByWeek = getDatesByWeekInMonthCalendar(month);

  return (
    <div
      style={{
        padding: "10px 6px",
        background: colors.bg.tertiary,
        borderRadius: "5px",
      }}
    >
      <div
        className="calendarHeader"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 8px 10px 8px",
        }}
      >
        <CalendarArrowButton
          title="Last Month"
          onClick={() => {
            const d = new Date(month.getTime());
            d.setMonth(d.getMonth() - 1);
            setMonth(d);
          }}
        >
          &larr;
        </CalendarArrowButton>
        <CalendarArrowButton
          title="Current Month"
          onClick={() => setMonth(getStartOfMonth(getStartOfToday()))}
          style={{
            fontSize: "14px",
            whiteSpace: "nowrap",
          }}
        >
          {MonthYearFormatter.format(month)}
        </CalendarArrowButton>
        <CalendarArrowButton
          title="Next Month"
          onClick={() => {
            const d = new Date(month.getTime());
            d.setMonth(d.getMonth() + 1);
            setMonth(d);
          }}
        >
          &rarr;
        </CalendarArrowButton>
      </div>
      <div className="calendarBox">
        <div
          className="weekDayInitials"
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {["M", "Tu", "W", "Th", "F", "Sa", "Su"].map((weekDayInitial) => {
            return (
              <div
                style={{
                  width: "calc(100% / 7)",
                  textAlign: "center",
                  color: colors.text.secondary,
                  fontSize: 12,
                }}
                key={weekDayInitial}
              >
                {weekDayInitial}
              </div>
            );
          })}
        </div>
        {daysByWeek
          .filter((days) => days.length)
          .map((daysInWeek) => {
            return (
              <CalendarWeek
                month={month}
                search={search}
                setSearchDate={setSearchDate}
                days={daysInWeek}
                key={daysInWeek[0].getTime()}
              />
            );
          })}
      </div>
    </div>
  );
};

const Calendar = ({ search, setSearchDate }: CalendarProps) => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [month, setMonth] = useState<Date>(getStartOfMonth(getStartOfToday()));

  return (
    <div
      className="sidebarCalendar nonselectable"
      style={{
        marginTop: 10,
        marginBottom: 4,
        color: colors.text.accent,
      }}
    >
      <div
        className="shortcuts"
        style={{
          marginBottom: 8,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <CalendarDefaultsButton
          selected={
            search.type === "date" &&
            search.q.getTime() === getStartOfToday().getTime()
          }
          onClick={() => {
            setMonth(getStartOfMonth(getStartOfToday()));
            setSearchDate(getStartOfToday());
          }}
        >
          Today
        </CalendarDefaultsButton>
        <CalendarDefaultsButton
          selected={
            search.type === "date" &&
            search.q.getTime() === getStartOfTomorrow().getTime()
          }
          onClick={() => {
            setMonth(getStartOfMonth(getStartOfToday()));
            setSearchDate(getStartOfTomorrow());
          }}
        >
          Tomorrow
        </CalendarDefaultsButton>
        <button
          title="Find another date"
          onClick={() => setShowCalendar(!showCalendar)}
          style={{
            background: showCalendar ? colors.bg.tertiary : "transparent",
            border: 0,
            cursor: "pointer",
            borderRadius: 5,
            fontSize: 14,
            margin: "0 3px",
            marginLeft: "auto",
            height: 26,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: colors.text.accent,
          }}
        >
          <CalendarIcon size={16} />
        </button>
      </div>
      {showCalendar && (
        <CalendarMonth
          month={month}
          setMonth={setMonth}
          search={search}
          setSearchDate={setSearchDate}
        />
      )}
    </div>
  );
};

export const SidebarCalendar = React.memo(Calendar);
