import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../utils/style";

interface Props {
  submitEditChanges: (inputValue: string) => void;
  errorMessage: string | null;
  setErrorMessage: (v: string | null) => void;
  setIsEditing: (v: boolean) => void;
  content: string;
}

export const EditInput = ({
  submitEditChanges,
  errorMessage,
  setErrorMessage,
  setIsEditing,
  content,
}: Props) => {
  const [inputValue, setInputValue] = useState(content);
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputEl.current?.focus();
    inputEl.current?.select();
  }, []);

  const generateErrorMessage = (v: string): string | null => {
    // allow the user to change the casing without error
    if (v.toLowerCase() === content.toLowerCase()) return null;
    if (v === "") {
      return "missing content";
    }
    if (v.length > 50) {
      return "must be shorter than 50 characters";
    }
    return null;
  };

  return (
    <div style={{ position: "relative", flexGrow: 1, display: "flex" }}>
      {errorMessage ? (
        <div
          style={{
            position: "absolute",
            color: "white",
            bottom: 26,
            left: -8,
            background: "red",
            whiteSpace: "pre",
            wordBreak: "break-word",
            fontSize: 12,
            maxWidth: 268,
            padding: 4,
            borderRadius: 2,
            zIndex: 2,
            overflow: "hidden",
            cursor: "not-allowed",
          }}
        >
          Error: {errorMessage}
        </div>
      ) : null}
      <input
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          const error = generateErrorMessage(e.target.value);
          setErrorMessage(error);
        }}
        onClick={(e) => e.stopPropagation()}
        onKeyPress={(e) => {
          e.stopPropagation();
          if (e.key === "Enter") {
            submitEditChanges(inputValue);
          }
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === "Escape") {
            setIsEditing(false);
            setInputValue(content);
            setErrorMessage(null);
          }
        }}
        ref={inputEl}
        onBlur={() => {
          submitEditChanges(inputValue);
        }}
        style={{
          flexGrow: 1,
          flexBasis: 50,
          border: `1px solid ${colors.border.secondary}`,
          borderRadius: 2,
        }}
      />
    </div>
  );
};
