import styled from "@emotion/styled";
import React from "react";
import { colors, radii, transitions } from "../../utils/style";
import { SortIcon } from "../img/SortIcon";
import { HashtagSortMode } from "./ElementBrowser";

interface Props {
  sortMode: string;
  setSortMode: (to: HashtagSortMode) => void;
}

const Button = styled.div`
  padding: 0.25rem 0.25rem;
  /* height: 24px; */
  border-radius: ${radii.medium};
  gap: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${colors.text.secondary};
  transition: ${transitions.light};

  &:hover {
    background: ${colors.bg.tertiary};
    color: ${colors.text.secondary};
  }
  &:active {
    color: ${colors.text.tertiary};
  }
`;

export const SidebarListSortToggle: React.FC<Props> = ({
  sortMode,
  setSortMode,
}) => {
  return (
    <Button
      className="selected-link-sort-toggle"
      onClick={() =>
        setSortMode(sortMode === "alphabetical" ? "popularity" : "alphabetical")
      }
    >
      <SortIcon width={16} />
      <span>{sortMode === "alphabetical" ? "A→Z" : "Top"}</span>
    </Button>
  );
};
