import React from "react";
import { useSetAtom } from "jotai";
import { scrollEditorToTop } from "../../../editorPage/EditorWithAutocomplete";
import { noteFormatter } from "../../../model/services";
import { NoteId } from "../../../model/types";
import { SearchQuery, SearchQuerySetter } from "../../../search/SearchQuery";
import { colors, mediumWidth } from "../../../utils/style";
import { SelectedLink } from "../SelectedLink";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GrabHandle } from "../GrabHandle";
import { isSidebarOpenAtom } from "../../atoms/isSidebarOpenAtom";
import { PinIcon } from "../../img/PinIcon";

export const PinnedNote: React.FC<{
  noteId: NoteId;
  id: string;
  search: SearchQuery;
  setSearch: SearchQuerySetter;
}> = (props) => {
  const { noteId, search, setSearch } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: noteId });

  const setShowSidebar = useSetAtom(isSidebarOpenAtom);

  const onSelect = () => {
    if (window.innerWidth <= mediumWidth) setShowSidebar(false);
    scrollEditorToTop();

    setSearch({
      type: "note",
      q: noteId,
      asPage: true,
    });
  };

  const selected = search.type === "note" && search.q === noteId;

  return (
    /*  CSS class for showing/hiding grab handle below */
    <div
      className="pinned-note-item"
      style={{
        margin: "4px 0",
        transform: CSS.Transform.toString(transform),
        transition: transition as any,
        userSelect: "none",
      }}
      ref={setNodeRef}
      onClick={onSelect}
      onKeyPress={(evt) => evt.key === "Enter" && onSelect()}
      {...attributes}
    >
      <SelectedLink
        id={`pinned-note-${noteId}`}
        selected={selected}
        title={noteFormatter.getNotePreview(noteId)}
      >
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div
            style={{
              width: 0,
              flex: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <PinIcon size={16} opacity={0.75} isActive />
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginLeft: 6,
              }}
            >
              {noteFormatter.getNoteEllipsis(noteId)}
            </span>
          </div>
          {/* Don't propagate the onClick events originating from the grab handle (they should not select the item) */}
          <div onClick={(e) => e.stopPropagation()}>
            <GrabHandle
              title="Reorder this pinned note"
              {...listeners}
              style={{
                color: selected ? colors.bg.primary : colors.text.secondary,
              }}
            >
              ⠿
            </GrabHandle>
          </div>
        </div>
      </SelectedLink>
    </div>
  );
};
