export const PinIcon = ({
  size,
  isActive = false,
  opacity = 1, //stopgap until we update sidebar styling to match figma
}: {
  size: number;
  isActive: boolean;
  opacity: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      flex: "none",
      opacity: opacity,
    }}
  >
    <title id="pinNoteIconTitle">Pin note</title>
    {isActive ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9V4H17C17.55 4 18 3.55 18 3C18 2.45 17.55 2 17 2H7C6.45 2 6 2.45 6 3C6 3.55 6.45 4 7 4H8V9C8 10.66 6.66 12 5 12V14H10.97V21L11.97 22L12.97 21V14H19V12C17.34 12 16 10.66 16 9Z"
        fill="currentColor"
      />
    ) : (
      <path
        d="M16,9V4l1,0c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H7C6.45,2,6,2.45,6,3v0 c0,0.55,0.45,1,1,1l1,0v5c0,1.66-1.34,3-3,3h0v2h5.97v7l1,1l1-1v-7H19v-2h0C17.34,12,16,10.66,16,9z"
        fillRule="evenodd"
      />
    )}
  </svg>
);
