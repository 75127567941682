import { EditorPage } from "../editorPage/EditorPage";

import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/nextjs";
import { isProd, sentryDsn } from "../utils/environment";
if (sentryDsn && isProd) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: isProd ? 0.2 : 1.0,
  });
}
export default EditorPage;
