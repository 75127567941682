import styled from "@emotion/styled";
import { colors } from "../../utils/style";

export const GrabHandle = styled.div`
  display: none;
  cursor: grab;
  color: ${colors.text.secondary};
  font-size: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  touch-action: none;

  :active {
    cursor: grabbing;
  }

  .pinned-note-item:hover &,
  .touch-device & {
    display: inline-block;
  }
`;
