import React, { useCallback, useEffect, useState } from "react";
import { useSetAtom } from "jotai";
import { scrollEditorToTop } from "../../../editorPage/EditorWithAutocomplete";
import { folderList } from "../../../model/services";
import { mediumWidth } from "../../../utils/style";
import { SelectedLink } from "../SelectedLink";
import { Menu } from "../../SidebarItem/Menu";
import { EditInput } from "../../SidebarItem/EditInput";
import { isSidebarOpenAtom } from "../../atoms/isSidebarOpenAtom";
import { FolderIcon } from "../../img/FolderFillIcon";
import OneLiner from "../OneLiner";
import { trackEvent } from "../../../analytics/analyticsHandlers";
import { useSearchState } from "../../../search/searchStateAtom";
import { toast } from "../../../editorPage/noteMenu/toast";
import { useNotifySidebarUpdate } from "../../atoms/sidebarUpdate";

interface Props {
  id: string;
  name: string;
  newId: string | null;
  setNewId: (id: string | null) => void;
  handleProps: any; // not typed in the lib
}

export const FolderItem: React.FC<Props> = ({
  id,
  name: content,
  handleProps,
  newId,
  setNewId,
}) => {
  const [search, setSearch] = useSearchState();
  const setShowSidebar = useSetAtom(isSidebarOpenAtom);
  const [isRenaming, setIsRenaming] = useState(id === newId);
  const [isHovering, setIsHovering] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateSidebar = useNotifySidebarUpdate();

  useEffect(() => {
    if (id === newId) {
      setIsRenaming(true);
    }
  }, [newId, id]);

  const select = useCallback(() => {
    if (window.innerWidth <= mediumWidth) {
      setShowSidebar(false);
    }
    scrollEditorToTop();
    setSearch({
      type: "folder",
      q: id,
    });
  }, [setSearch, id, setShowSidebar]);

  const rename = useCallback(
    (name: string) => {
      if (error === null) {
        folderList.update({ id, name }, true);
      }
      setIsRenaming(false);
      setNewId && setNewId(null);
      setSearch({ type: "folder", q: id });
      trackEvent(["FOLDER", "UPDATED", id]);
    },
    [error, id, setNewId, setSearch],
  );

  const remove = useCallback(async () => {
    const { folder, undoDelete } = folderList.delete(id, true);
    toast(`Folder ${folder.name} deleted`, {
      text: "undo",
      onClick() {
        undoDelete();
        updateSidebar();
      },
    });
    setSearch({ type: "*" });
    trackEvent(["FOLDER", "DELETED", id]);
  }, [id, setSearch]);

  return (
    <div
      className="nonselectable"
      style={{ flexGrow: 1, minWidth: 0 }}
      onKeyDown={select}
    >
      <SelectedLink
        onMouseDown={select}
        tabIndex={0}
        title={content}
        selected={search.type === "folder" && search.q === id}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <OneLiner
          icon={<FolderIcon isActive size={16} opacity={0.75} />}
          // Allow the overflow when renaming since the EditInput might want to show an error message
          allowOverflow={isRenaming}
          content={
            isRenaming ? (
              <EditInput
                submitEditChanges={rename}
                errorMessage={error}
                setErrorMessage={setError}
                setIsEditing={setIsRenaming}
                content={content}
              />
            ) : (
              content
            )
          }
          trailingButtons={
            !isRenaming && (
              <>
                <Menu
                  isHovering={isHovering}
                  isSelected={search.type === "folder" && search.q === id}
                  setIsHoveringSidebarItem={setIsHovering}
                  setIsEditing={setIsRenaming}
                  errorMessage={error}
                  deleteFolderAndRedirect={remove}
                />
                <div className={"grab-handle"} {...handleProps} />
              </>
            )
          }
        />
      </SelectedLink>
    </div>
  );
};
