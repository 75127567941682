import styled from "@emotion/styled";
import { ChevronLeft, ArrowLeft } from "react-feather";
import { isGreaterThanMobileWidth } from "../utils/environment";
import { useBack } from "./navigateHistory";

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  min-width: 44px;
  min-height: 44px;
  background: transparent;
  border: 0;
  color: var(--color-text-secondary);
  :disabled {
    color: var(--color-text-tertiary);
    cursor: default;
  }
  padding: 0;
`;

const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`;

export const BackButton = ({
  callback,
  disabled = false,
  size = 20,
}: {
  callback?: () => void;
  disabled?: boolean;
  size?: number;
}) => {
  const back = useBack();
  return (
    <Button
      id="back-button"
      role="tooltip"
      data-microtip-position="right"
      aria-label="Click to go back"
      onMouseDown={() => {
        back();
        callback?.();
      }}
      disabled={disabled}
    >
      <ChevronContainer>
        {isGreaterThanMobileWidth ? (
          <ArrowLeft size={size} />
        ) : (
          <ChevronLeft size={size} />
        )}
      </ChevronContainer>
    </Button>
  );
};
