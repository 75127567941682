import React, { forwardRef } from "react";
import { ChevronDown } from "react-feather";

export const ExpandableAndDraggable = forwardRef<HTMLDivElement, any>(
  (props, ref) => {
    const {
      style,
      clone,
      ghost,
      depth,
      childCount,
      collapsed,
      onCollapse,
      wrapperRef,
    } = props;
    const toggleWidth = 30;
    const chevronSize = 20;

    return (
      <div
        ref={wrapperRef}
        className={["tree-wrapper", clone && "clone", ghost && "ghost"]
          .filter((a) => !!a)
          .join(" ")}
        style={{
          ...style,
          paddingLeft: clone ? 24 : 24 * depth,
        }}
      >
        <div className="tree-item" ref={ref}>
          <div
            className="collapse-container"
            style={{
              width: toggleWidth,
            }}
          >
            {childCount > 0 && (
              <button
                className={["collapse", collapsed && "collapse-collapsed"]
                  .filter((a) => !!a)
                  .join(" ")}
                onClick={onCollapse}
              >
                <ChevronDown size={chevronSize} />
              </button>
            )}
          </div>
          {props.children}
        </div>
      </div>
    );
  },
);
