import React from "react";
import { Hash } from "react-feather";
import { useSearchState } from "../../search/searchStateAtom";
import OneLiner from "../Elements/OneLiner";
import { SelectedLink } from "../Elements/SelectedLink";
import { CountMenu } from "./Menu";

interface Props {
  count: number | null;
  content: string;
  itemId: string;
  setSearch: () => void;
}

export const SidebarItem = React.memo(function SidebarItem(props: Props) {
  const [searchQuery] = useSearchState();

  const isSelected =
    (searchQuery.type === "hashtag" &&
      searchQuery.q.toLowerCase() === props.itemId.toLowerCase()) ||
    (searchQuery.type === "text" &&
      searchQuery.q.toLowerCase() === props.itemId.toLowerCase());

  return (
    <li>
      <SelectedLink
        tabIndex={0}
        onMouseDown={(e) => {
          e.preventDefault();
          props.setSearch();
        }}
        selected={isSelected}
      >
        <OneLiner
          icon={<Hash size={16} style={{ opacity: 0.75 }} />}
          content={props.content}
        />
        {props.count && (
          <CountMenu className="selected-link-count" isSelected={isSelected}>
            {props.count.toLocaleString()}
          </CountMenu>
        )}
      </SelectedLink>
    </li>
  );
});
