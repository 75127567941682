import React from "react";
import { countTodos } from "./countUtils";
import { noteList } from "../model/services";
import { colors, mediumWidth } from "../utils/style";
import { SelectedLink } from "./Elements/SelectedLink";
import { useSetAtom } from "jotai";
import { useRefreshWithSidebarUpdate } from "./atoms/sidebarUpdate";
import { useRefreshWithEditorUpdate } from "../editorPage/atoms/editorUpdate";
import { HashtagList } from "./Elements/HashtagList";
import { SidebarCalendar } from "./SidebarCalendar";
import { PinnedNotesSection } from "./Elements/PinnedNotes/PinnedNotesSection";
import { isSidebarOpenAtom } from "./atoms/isSidebarOpenAtom";
import { FoldersSection } from "./Elements/Folders/FolderSection";
import { useSearchState } from "../search/searchStateAtom";
import { CheckSquare, Globe, Home, Icon } from "react-feather";
import { shortcuts, getShortcutString } from "../shortcuts/shortcuts";
import { trackEvent } from "../analytics/analyticsHandlers";
import { SearchQuerySetter } from "../search/SearchQuery";

type Props = {
  children: React.ReactNode;
  id: string;
  label?: string;
  icon: Icon;
  count: number;
  onSelect: () => void;
  selected: boolean;
};

const SidebarLink = React.forwardRef<HTMLDivElement, Props>(
  ({ children, id, label, icon, count, onSelect, selected }, ref) => {
    const tooltipProps = label
      ? {
          role: "tooltip",
          "data-microtip-position": "bottom",
          "aria-label": label,
        }
      : null;

    const Icon = icon;

    return (
      <SelectedLink
        id={id}
        ref={ref}
        tabIndex={0}
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            onSelect();
          }
        }}
        onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e?.preventDefault();
          onSelect();
        }}
        selected={selected}
        {...tooltipProps}
      >
        <Icon
          size={16}
          color={selected ? colors.bg.primary : colors.text.accent}
        />
        {children}
        <span
          style={{
            fontSize: 12,
            float: "right",
            marginLeft: "auto",
            color: selected ? colors.bg.primary : colors.text.secondary,
          }}
        >
          {count.toLocaleString()}
        </span>
      </SelectedLink>
    );
  },
);

export const SidebarContent: React.FC<{
  sidebarFirstFocusableRef: React.RefObject<HTMLDivElement>;
}> = React.memo(({ sidebarFirstFocusableRef }) => {
  const [search, setSearch] = useSearchState();

  const setShowSidebar = useSetAtom(isSidebarOpenAtom);

  const setSearchFromSidebar: SearchQuerySetter = (state) => {
    setSearch(state);
    if (window.innerWidth <= mediumWidth) {
      setShowSidebar(false);
    }
    trackEvent(["SEARCH", "FROM_SIDEBAR"]);
  };

  // recompute the sidebar (pinned notes, etc.) on new edits
  useRefreshWithSidebarUpdate();
  useRefreshWithEditorUpdate();

  // for now, just call this once and pass to the various
  // util functions that count occurrences
  const allNotes = noteList.getAll();

  return (
    <>
      {/* padding */}
      <div style={{ marginTop: 16, visibility: "hidden" }}></div>

      <SidebarLink
        id="all-notes"
        ref={sidebarFirstFocusableRef}
        label={`All Notes ${getShortcutString(shortcuts.backToAllNotes)}`}
        icon={Home}
        count={allNotes.length}
        onSelect={() => setSearchFromSidebar({ type: "*" })}
        selected={search.type === "*"}
      >
        <span style={{ paddingLeft: 6 }}>All Notes</span>
      </SidebarLink>

      <SidebarLink
        id="all-incompletes"
        label={`Add to-dos you can check off\nby typing [ ] in your notes`}
        icon={CheckSquare}
        count={countTodos(allNotes)}
        onSelect={() => setSearchFromSidebar({ type: "is", q: "incomplete" })}
        selected={search.type === "is" && search.q === "incomplete"}
      >
        <span style={{ paddingLeft: 6 }}>To-dos</span>
      </SidebarLink>

      <SidebarLink
        id="all-public"
        label={`Notes you've shared publicly\nwith a link appear here`}
        icon={Globe}
        count={allNotes.filter((note) => note.readAll).length}
        onSelect={() => setSearchFromSidebar({ type: "is", q: "public" })}
        selected={search.type === "is" && search.q === "public"}
      >
        <span style={{ paddingLeft: 6 }}>Public Notes</span>
      </SidebarLink>

      <SidebarCalendar
        search={search}
        setSearchDate={(date) =>
          setSearchFromSidebar({ type: "date", q: date })
        }
      />

      <PinnedNotesSection search={search} setSearch={setSearchFromSidebar} />

      <FoldersSection />

      <HashtagList setSearch={setSearchFromSidebar} />
    </>
  );
});
