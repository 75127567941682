import React from "react";

export const SortIcon = (props: {
  width: number;
  style?: React.CSSProperties;
}) => (
  <svg
    width={props.width}
    height={props.width}
    viewBox="0 0 16 16"
    style={props.style}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 5L8 5"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
    />
    <path
      d="M12 8L8 8"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
    />
    <path
      d="M11 11L8 11"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
    />
    <path
      d="M2 6L4 4M4 4L6 6M4 4V12"
      stroke="currentColor"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
