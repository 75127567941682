import React from "react";
import { noteList } from "../../model/services";
import { SearchQuerySetter } from "../../search/SearchQuery";
import { countHashtag } from "../countUtils";
import ElementBrowser, { SidebarElement } from "./ElementBrowser";

export const HashtagList: React.FC<{ setSearch: SearchQuerySetter }> = ({
  setSearch,
}) => {
  const elements: SidebarElement[] = countHashtag(noteList).map(
    ({ id, count, content }) => ({
      id,
      count,
      content: content.slice(1), // remove the leading #
      setSearch: () => setSearch({ type: "hashtag", q: id }),
    }),
  );
  return (
    <ElementBrowser header="Hashtags" type="hashtag" elements={elements} />
  );
};
