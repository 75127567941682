import React, { useCallback } from "react";
import Search from "../search/SearchBar";
import { ToggleSideBarButton } from "./ToggleSideBarButton";
import {
  NewNoteButtonMobileWithInteraction,
  NewNoteButtonWithInteraction,
} from "./NewNoteButton";
import styled from "@emotion/styled";
import { colors } from "../utils/style";
import { isGreaterThanMobileWidth, isTouchDevice } from "../utils/environment";
import { BackButton } from "./BackButton";
import { useSearchState } from "../search/searchStateAtom";
import { HomeButton } from "./HomeButton";
import { noteSearcher } from "../model/services";
import { copyTextToClipboard } from "../export/copyToClipboard";
import { useRefreshWithEditorUpdate } from "./atoms/editorUpdate";
import { noteToProsemirrorNode } from "../editor/bridge";
import { Fragment, Slice } from "prosemirror-model";
import { serializeNode } from "../editor/utils/serializeNode";
import { trackEvent } from "../analytics/analyticsHandlers";

const HeaderContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.border.secondary};
  height: var(--header-height);
  position: relative;
  width: 100%;
  background: ${colors.bg.primary};
  -webkit-app-region: drag;
`;

const HeaderContent = styled.div<{ width: number }>(
  (props) => `
  display: flex;
  margin: 0px auto;
  align-items: center;
  width: ${props.width}px;
`,
);

const HeaderLeft = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`;

const HeaderRight = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  min-width: 8px;
`;

const HeaderTrailingIcons = styled.div`
  display: flex;
  align-items: baseline;
  justify-items: center;
  padding-left: 3px;
  padding-right: 8px;
`;

interface Props {
  contentMaxWidth: number;
}

export const Header = React.memo((props: Props) => {
  const [searchQuery] = useSearchState();
  const latestNetworkEdit = useRefreshWithEditorUpdate();
  const atAllNotes =
    searchQuery.type === "*" ||
    (searchQuery.type === "note" && !searchQuery.asPage);

  const copyAllNotes = useCallback(() => {
    const { notes } = noteSearcher.searchNotes(
      searchQuery,
      Number.MAX_SAFE_INTEGER,
    );
    const noteNodes = notes.map((note) =>
      noteToProsemirrorNode(note.entry, "/", (n) => ({
        highlighted: false,
        folderId: note.entry.folderId,
      })),
    );
    const fragment = Fragment.from(noteNodes);
    const contentToCopy = serializeNode(
      new Slice(fragment, 0, fragment.childCount - 1),
    );

    if (contentToCopy.length === 0) {
      return;
    }

    if (navigator.share) {
      navigator
        .share({
          text: contentToCopy,
        })
        // We can safely ignore the error since an error is thrown if the user cancels the share sheet
        .catch((error) => console.log(`navigator.share error: ${error}`));
    } else {
      // As fallback, try to copy text to clipboard
      copyTextToClipboard(contentToCopy);
    }
    trackEvent(["NOTE", "SHARE_ALL"]);

    // we want to refresh the search when there is a network update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, latestNetworkEdit]);

  return (
    <HeaderContainer role="presentation" id="editor-page-header">
      <HeaderLeft>
        {isGreaterThanMobileWidth ? (
          <>
            <ToggleSideBarButton
              callback={() => trackEvent(["SIDEBAR", "TOGGLE_FROM_HEADER"])}
            />
            <HomeButton
              callback={() => trackEvent(["GO", "HOME_FROM_HEADER"])}
            />
            <BackButton
              callback={() => trackEvent(["GO", "BACK_FROM_HEADER"])}
              disabled={atAllNotes}
            />
          </>
        ) : !atAllNotes ? (
          <>
            <HomeButton
              callback={() => trackEvent(["GO", "HOME_FROM_HEADER"])}
            />
            <BackButton
              callback={() => trackEvent(["GO", "BACK_FROM_HEADER"])}
            />
          </>
        ) : (
          <ToggleSideBarButton
            callback={() => trackEvent(["SIDEBAR", "TOGGLE_FROM_HEADER"])}
          />
        )}
      </HeaderLeft>
      <HeaderContent width={props.contentMaxWidth}>
        <Search />
        {isTouchDevice && (
          <HeaderTrailingIcons onMouseDown={copyAllNotes}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 0 24 24"
                style={{ margin: "0 auto", display: "block", marginTop: "3px" }}
              >
                <path
                  d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z"
                  fill={colors.text.secondary}
                />
              </svg>
            </div>
          </HeaderTrailingIcons>
        )}
        {isTouchDevice ? (
          <NewNoteButtonMobileWithInteraction />
        ) : (
          <NewNoteButtonWithInteraction />
        )}
      </HeaderContent>
      <HeaderRight />
    </HeaderContainer>
  );
});
